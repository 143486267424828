<template>
  <div class="relative min-h-screencalc-minus-nav flex flex-col" :class="templateClass">
    <LoaderMainCss/>

    <ClientOnly>
      <template v-if="$config.public.NEW_NAV_ENABLED">
        <NavigationLHS
        v-show="navState === 'full'"
        has-rotator
        />
        <NavigationLHSCollapsed
          has-rotator
        />
        <PanelMessageRotator
          v-show="isUserLogged"
          :alterative-layout="filteredRoute"
          show-at-front
        />
        <NavigationTop :alterative-layout="filteredRoute"/>
        <NavigationTopMobile />
      </template>
      <template v-else>
        <NavigationOldLHS v-if="navState === 'full'"/>
        <NavigationOldLHSCollapsed />
        <PanelMessageRotator />
        <NavigationOldTop />
        <NavigationOldTopMobile />
      </template>
    </ClientOnly>

    <div
      class="overflow-x-hidden"
      :class="wrapperClass"
    >
      <ClientOnly>
        <main class="px-3 py-5 relative z-0 w-full mx-auto max-w-5xl min-h-screencalc-minus-nav">
          <slot/>
        </main>
        <FooterMain/>
      </ClientOnly>
    </div>

    <ClientOnly>
      <ChatDiscordMain v-if="discordChatEnabled"/>
    </ClientOnly>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useUiStore } from '@/store/ui';
import { useAuthStore } from '@/store/auth';

const uiStore = useUiStore();
const { discordChatEnabled, chatState, navState, templateClass, } = storeToRefs(uiStore);

const authStore = useAuthStore();
const { isUserLogged, } = storeToRefs(authStore);

const route = useRoute();

const updateTrigger = ref(false);

const wrapperClass = computed(() => {
  const classes = [];

  if (navState.value === 'full') {
    classes.push('lg:pl-[--nav-left-width]');
  } else if (navState.value === 'mini') {
    classes.push('lg:pl-[--nav-left-mini-width]');
  }
  if (!['hidden', 'disabled',].includes(chatState.value)) {
    classes.push('xl:pr-[--chat-desktop-width]');
  }
  return classes.join(' ');
});

const filteredRoute = computed(() => {
  const currentRoute = route.path;
  const excludedRoutes = [
    '/contact-us/',
    '/bridge/',
    '/promotions/',
    '/promotions/salon-rouge/',
    '/underage-gaming-policy/',
    '/privacy-policy/',
    '/cookie-policy/',
    '/games/terms-and-conditions/',
    '/sports/terms-and-conditions/',
    '/underage-gaming-policy/',
    '/self-exclusion-policy/',
    '/responsible-gambling/',
    '/games/kyc/',
  ];

  return excludedRoutes.includes(currentRoute);
});

function updateWrapperClass() {
  updateTrigger.value = !updateTrigger.value;
}

// For some reason the wrapper class is not updated when the navState is changed on page load. This is a workaround.
updateWrapperClass();
</script>
